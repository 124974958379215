<template>
    <div class="main-container">
        <div class="thank-you">
            ¡Error! No ha sido posible completar la compra
            <span class="material-icons-outlined error">
                error_outline
            </span>
            <div class="error-more-info">
                Vuelva a intentarlo o pongase en contacto con atención al cliente
            </div>
            <div class="contact-info">
                <div class="mobile-container info-element">
                    <span class="material-icons-outlined">
                        call
                    </span>
                    <span>911 331 556</span>
                </div>
                <div class="whatsapp-container info-element">
                    <svg class="whatsapp" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100"
                        viewBox="0 0 24 24">
                        <path
                            d="M19.077,4.928c-2.082-2.083-4.922-3.134-7.904-2.894C7.164,2.356,3.65,5.144,2.474,8.99 c-0.84,2.748-0.487,5.617,0.881,7.987L2.059,21.28c-0.124,0.413,0.253,0.802,0.67,0.691l4.504-1.207 c1.459,0.796,3.101,1.215,4.773,1.216h0.004c4.195,0,8.071-2.566,9.412-6.541C22.728,11.563,21.762,7.616,19.077,4.928z M16.898,15.554c-0.208,0.583-1.227,1.145-1.685,1.186c-0.458,0.042-0.887,0.207-2.995-0.624c-2.537-1-4.139-3.601-4.263-3.767 c-0.125-0.167-1.019-1.353-1.019-2.581S7.581,7.936,7.81,7.687c0.229-0.25,0.499-0.312,0.666-0.312c0.166,0,0.333,0,0.478,0.006 c0.178,0.007,0.375,0.016,0.562,0.431c0.222,0.494,0.707,1.728,0.769,1.853s0.104,0.271,0.021,0.437s-0.125,0.27-0.249,0.416 c-0.125,0.146-0.262,0.325-0.374,0.437c-0.125,0.124-0.255,0.26-0.11,0.509c0.146,0.25,0.646,1.067,1.388,1.728 c0.954,0.85,1.757,1.113,2.007,1.239c0.25,0.125,0.395,0.104,0.541-0.063c0.146-0.166,0.624-0.728,0.79-0.978 s0.333-0.208,0.562-0.125s1.456,0.687,1.705,0.812c0.25,0.125,0.416,0.187,0.478,0.291 C17.106,14.471,17.106,14.971,16.898,15.554z">
                        </path>
                    </svg>
                    662 306 561
                </div>
                <div class="mail info-element">
                    <span class="material-icons-outlined">
                        local_post_office
                    </span>
                    info@eurekers.com
                </div>
            </div>
        </div>
        <div class="buttons-container">
            <div class="retorno-graficador">
                <a href="/app/graficador-dinamico/"><button class="back-button">Volver al graficador</button></a>
            </div>
            <div class="retorno-graficador">
                <a href="/app/store/"><button class="back-button">Volver a la tienda</button></a>
            </div>
        </div>
    </div>
</template>
<script>
import APICaller from "@/components/codeComponents/APICaller.js";
export default {
    mixins: [APICaller],
    name: 'Error de compra',
    components: {},
    data() {
        return {
            products: [],
        }
    },
    mounted() { this.loadData(); },
    computed: {},
    methods: {
        async loadData() {
            await this._deleteCart();
        },

        async _deleteCart() {
            let success = _ => { }

            let url = "/api/v1/eurekers_marketplace/delete-cart";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('510', 'Error al recuperar los productos.');

            await this._getAPICall(url, successHandler, failureHandler);
        }
    },
}
</script>
<style scoped>
.thank-you {
    font-size: 35px;
    text-align: center;
    padding-top: 6%;
}

.carrito-descripcion {
    text-align: center;
    padding: 50px;
    font-size: 20px;
    padding-left: 25%;
    padding-right: 25%;
    padding-bottom: 13px !important;
}

.product {
    display: flex;
    padding: 30px;
}

.product-image {}

.product-details {
    font-size: 15px;
}

.product-name {
    margin-top: 2px;
    margin-left: 50px;
    text-align: left;
    width: 100%;
}

.product-name>* {
    margin-top: 10px;
}

.product {
    border: 2px solid rgba(139, 139, 139, 0.532);
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 20px;
}

.back-button {
    background-color: #00ADD9FF;
    color: aliceblue;
    border-radius: 15px;
    padding: 15px;
    border: 1px solid white;
}

.retorno-graficador {
    text-align: center;
    margin-left: 10px;
    cursor: pointer;
}

.error {
    font-size: 40px;
    color: rgb(227, 6, 6);
    padding-top: 12px;
}

.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.back-button {
    cursor: pointer;
}

.error-more-info {
    font-size: 19px;
    margin-top: 10px;
    color: rgb(85, 85, 85);
}

.whatsapp {
    font-size: 10px;
    width: 35px;
    height: 35px;
}

.contact-info {
    font-size: 20px;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid black;
    width: fit-content;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 20px;
}

.info-element {
    margin-top: 10px;
    top: 50%;
    left: 50%;
}
</style>